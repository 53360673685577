<script setup>
import { onMounted, ref } from 'vue';

//Ein Prop ist eine Eigenschaft, die von der übergeordneten
// Komponente an die untergeordnete Komponente übergeben wird.
defineProps({
    modelValue: String,
});


// defineEmits: Diese Funktion definiert, welche Events die Komponente auslösen kann.
// update:modelValue: Dies ist ein benutzerdefiniertes Event, das ausgelöst wird, wenn sich der Wert des Eingabefeldes ändert.
// Es wird verwendet, um den neuen Wert an die Elternkomponente zurückzugeben.
defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input ref="input" class="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
        :value="modelValue" @input="$emit('update:modelValue',
            $event.target.value
        )">
</template>

<!-- <input>-Element: Dies ist das Eingabeelement der Komponente.

ref="input": Weist das Eingabeelement der zuvor definierten input-Referenz zu.
class="...": Fügt CSS-Klassen hinzu, um das Eingabeelement zu stylen.

:value="modelValue": Bindet den Wert des Eingabeelements an die modelValue-Eigenschaft.
Dies sorgt dafür, dass der Wert des Eingabeelements immer mit dem Wert von modelValue synchronisiert ist.

@input="$emit('update:modelValue', $event.target.value)": Hört auf das input-Event und sendet das
update:modelValue-Event mit dem neuen Wert des Eingabeelements. Dadurch wird die Änderung des Wertes an
die Elternkomponente weitergegeben. -->
